<template>
  <div class="token-datas">
    <div class="container">
      <h1 class="title headline-l">{{ $t("ebsoScreen.tokenDatas.title") }}</h1>
      <bordered-box
        :borderColor="'#FCBB11'"
        :boxShadow="'none'"
        :background="'transparent'"
        :hasBorderAround="true"
      >
        <template v-slot:description>
           <ul class="list left-col">
            <li v-for="(key, i) in informationKeysLeft" :key="i">
              <span class="label">{{ $t(`ebsoScreen.tokenDatas.list.${key}.label`) }}</span>
              <span class="value">{{ $t(`ebsoScreen.tokenDatas.list.${key}.value`) }}</span>
            </li>
          </ul>
          <ul class="list right-col">
            <li v-for="(key, i) in informationKeysRight" :key="i">
              <span class="label">{{ $t(`ebsoScreen.tokenDatas.list.${key}.label`) }}</span>
              <span class="value">{{ $t(`ebsoScreen.tokenDatas.list.${key}.value`) }}</span>
            </li>
          </ul>
        </template>
      </bordered-box>
      <div class="button-row">
        <base-button size="large" type="blockstock-yellow" :text="$t('ebsoScreen.tokenDatas.buttonText')" @clicked="handleButtonClick()" />
      </div>
    </div>
  </div>
</template>

<script>
import BorderedBox from "@/components/elements/BorderedBox";
import BaseButton from "@/components/elements/Button";

export default {
  name: "TokenDatasSection",
  props: {},
  components: {
    BorderedBox,
    BaseButton
  },
  data: () => ({
    informationKeysLeft: ["type", "symbol", "transferFee"],
    informationKeysRight: ["account", "smartContract"]
  }),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      window.open("https://github.com/blockben-official/ebso", "_blank");
    }
  }
}
</script>
 
<style lang='scss' scoped>
.token-datas {
  padding: 75px 0 70px;
  border-bottom: 1px solid $blockstock-yellow;

  .container {
    @media (max-width: $breakpoint-xs) {
        padding: 0;
    }
  }
}

.title {
  color: $blockstock-yellow;
  text-align: center;
  margin: 0 0 40px;
}

.bordered-box {
  max-width: 935px;
  margin: 0 auto;
  padding: 25px 25px 35px 15px;

  @media (max-width: $breakpoint-md) {
    max-width: none;
  }

  @media (max-width: $breakpoint-sm) {
    padding: 40px 20px;
  }

  @media (max-width: $breakpoint-xs) {
    &.border-around {
      border: 0;
      border-radius: 0;
    }
  }

  &::v-deep {
    .description-wrapper {
      display: flex;

      @media (max-width: $breakpoint-sm) {
        flex-wrap: wrap;
      }
    }
  }

  .list {
    width: 50%;
    padding: 0;
    margin: 0;
    list-style-type: none;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }

    li {
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.label,
.value {
  font-size: 16px;
  line-height: 30px;
}

.label {
  display: block;
  font-family: "HKNova-Bold", sans-serif;
}

.value {
  word-break: break-word;
}

.button-row {
  text-align: center;
  margin: 60px 0 0;
}
</style>