<template>
  <div class="video-section">
    <div class="container">
      <div class="embed-responsive embed-responsive-16by9">
      <iframe
        class="responsive-iframe"
        :src="`${
          $i18n.locale == 'hu'
          ? 'https://www.youtube.com/embed/UCr1FYnApBo?cc_lang_pref=hu&cc_load_policy=1'
          : 'https://www.youtube.com/embed/UCr1FYnApBo'
        }`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        height="450"
      ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoSection",
  data() {
    return {
      videoSrc: "https://www.youtube.com/watch?v=UCr1FYnApBo&hl=hu"
    }
  }
}
</script>

<style lang="scss" scoped>
.video-section {
  padding: 80px 0 0;

  @media (max-width: $breakpoint-md) {
    padding: 40px 0 0;
  }
}
</style>