<template>
  <div class="gold-coverage-section">
    <div class="container">
      <h1 class="title headline-l">{{ $t("ebsoScreen.goldCoverage.title") }}</h1>
      <div class="box-list">
        <div v-for="(box, i) in boxes" :key="i" class="box">
          <h3 class="title">
            <span class="icon"><img src="/images/icons/bb-icon_gold-pipe.svg"></span>
            {{ $t(box.title) }}
          </h3>
          <p class="description">{{ $t(box.description) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoldCoverageSection",
  props: {},
  components: {},
  data: () => ({
    boxes: [
      {
        title: "ebsoScreen.goldCoverage.digitalGold.title",
        description: "ebsoScreen.goldCoverage.digitalGold.description"
      },
      {
        title: "ebsoScreen.goldCoverage.natrix.title",
        description: "ebsoScreen.goldCoverage.natrix.description"
      }
    ]
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.gold-coverage-section {
  padding: 70px 0 90px;
  border-bottom: 1px solid $ebso-section-border-color;
}

.title {
  color: $blockstock-yellow;
  margin: 0 0 80px;

  @media (max-width: $breakpoint-xs) {
    margin: 0 0 35px;
  }
}

.box-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 50px;

  @media (max-width: $breakpoint-xs) {
    grid-template-columns: 1fr;
    row-gap: 50px;
    column-gap: 0;
  }
}

.box {
  border: 1px solid $blockstock-yellow;
  border-radius: 10px;
  padding: 40px 40px 30px 65px;

  @media (max-width: $breakpoint-lg) {
    padding: 30px 15px 30px 20px;
  }
  
  .title {
    font-family: "HKNova-Bold", sans-serif;
    margin: 0 0 20px;
    font-size: 24px;
    line-height: 1;
    display: flex;
    align-items: center;

    .icon {
      margin: 0 15px 0 0;
    }
  }

  .description {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>