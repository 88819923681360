import { render, staticRenderFns } from "./TokenDatasSection.vue?vue&type=template&id=2a6cdb7e&scoped=true&"
import script from "./TokenDatasSection.vue?vue&type=script&lang=js&"
export * from "./TokenDatasSection.vue?vue&type=script&lang=js&"
import style0 from "./TokenDatasSection.vue?vue&type=style&index=0&id=2a6cdb7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6cdb7e",
  null
  
)

export default component.exports