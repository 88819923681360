<template>
  <div id="ebso-screen">
    <jumbotron
      :textColor="'#fff'"
      :description="$t('ebsoScreen.welcome.description')"
      :background="'#1B1B1B'"
      :backgroundImage="'/images/sections/ebso/header_image.jpg'"
      :mobileHeight="415"
      :logoSrc="'/images/logos/e-blockstock-logo.svg'"
      :mobileBackgroundImage="'/images/sections/ebso/header_image-mobile.jpg'"
    >
      <template slot:chart>
        <!-- TODO add chart component -->
      </template>
    </jumbotron>
    <video-section />
    <introduction-section />
    <gold-coverage-section />
    <bso-and-ebso-section />
    <audit-and-steps-section />
    <token-datas-section />
    <!-- <partners-section /> -->
    <warning-section :backgroundColor="'#1B1B1B'" />
    <other-products
      hiddenProductKey="ebso"
      cardBorderColor="#E8A83B"
      cardShadow="none"
      cardBackgroundColor="transparent"
      cardDescriptionColor="#fff"
    />
  </div>
</template>

<script>
import VideoSection from "@/components/sections/ebso/VideoSection";
import IntroductionSection from "@/components/sections/ebso/IntroductionSection";
import GoldCoverageSection from "@/components/sections/ebso/GoldCoverageSection";
import AuditAndStepsSection from "@/components/sections/ebso/AuditAndStepsSection";
import TokenDatasSection from "@/components/sections/ebso/TokenDatasSection";
import PartnersSection from "@/components/sections/ebso/PartnersSection";
import OtherProducts from "@/components/sections/OtherProducts";
import BsoAndEbsoSection from "@/components/sections/ebso/BsoAndEbsoSection";
import WarningSection from "@/components/sections/bit/WarningSection";

export default {
  name: "EbsoScreen",
  props: {},
  components: {
    VideoSection,
    IntroductionSection,
    GoldCoverageSection,
    AuditAndStepsSection,
    TokenDatasSection,
    PartnersSection,
    OtherProducts,
    BsoAndEbsoSection,
    WarningSection
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>
 
<style lang='scss' scoped>
#ebso-screen {
  background-color: $dark-grey;
  color: $white;
}

.jumbotron {
  border-bottom: 1px solid $ebso-section-border-color;

  &::v-deep {
    .content-wrapper {
      @media (max-width: $breakpoint-xs) {
        padding-top: 130px;
      }
    }

    .top-section {
      .logo {
        max-height: 75px;
      }
    }
  }
}

.other-products {
  &::v-deep {
    .section-title {
      color: $blockstock-yellow;
    }
  }
}

.warning-section {
  border-bottom: 1px solid $ebso-section-border-color;
}
</style>