<template>
  <div class="introduction-section">
    <div class="container">
      <div class="row introduction-row">
        <div class="col-12 col-md-7 col-lg-6 content-col">
          <div class="properties-block">
            <h1 class="title headline-l">{{ $t("ebsoScreen.introduction.properties.title") }}</h1>
            <ul>
              <li v-for="(textKey, i) in propertiesList" :key="i">
                {{ $t(textKey) }}
              </li>
            </ul>
          </div>
          <div class="values-block">
            <h1 class="title headline-l">{{ $t("ebsoScreen.introduction.values.title") }}</h1>
            <p class="short-description text-l">{{ $t("ebsoScreen.introduction.values.shortDescription") }}</p>
            <p class="description text-l">{{ $t("ebsoScreen.introduction.values.description") }}</p>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-6 illustration-col">
          <div class="illustration-wrapper h-100 d-flex align-items-center justify-content-end">
            <img src="/images/sections/ebso/introduction.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroductionSection",
  props: {},
  components: {},
  data: () => ({
    propertiesList: [
      "ebsoScreen.introduction.properties.list.gold",
      "ebsoScreen.introduction.properties.list.validator",
      "ebsoScreen.introduction.properties.list.rising",
      "ebsoScreen.introduction.properties.list.developer"
    ]
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.introduction-section {
  padding: 90px 0 110px;
  border-bottom: 1px solid $ebso-section-border-color;
}

.introduction-row {
  @media (max-width: $breakpoint-sm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.title {
  font-family: "HKNova-Bold", sans-serif;
  font-weight: 700;
  color: $blockstock-yellow;
  margin: 0 0 24px;
}

.properties-block {
  margin: 0 0 110px;

  @media (max-width: $breakpoint-xs) {
    margin: 0 0 40px;
  }
 
  ul {
    padding: 0 0 0 30px;
    line-height: 30px;

    @media (max-width: $breakpoint-md) {
      padding: 0 0 0 20px;
    }
  }
}

.values-block {
  .text-l {
    color: $white;
  }
}

.short-description {
  font-family: "HKNova-Bold", sans-serif;
  margin: 0 0 35px;
}

.content-col {
  @media (max-width: $breakpoint-sm) {
    order: 2;
  }
}

.illustration-col {
  @media (max-width: $breakpoint-sm) {
    order: 1;
    margin: 0 0 40px;
    padding: 0;
  }
}

.illustration-wrapper {
  img {
    @media (max-width: $breakpoint-md) {
      margin: 0 auto
    }
  }
}
</style>