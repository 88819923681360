<template>
  <div class="partners-section">
    <div class="container">
      <h1 class="title headline-l">{{ $t("ebsoScreen.ebsoPartners.title") }}</h1>
      <ul class="partners">
        <li v-for="(partner, i) in partners" :key="i">
          <a :href="partner.link">
            <img class="logo" :src="partner.logoSrc">
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnersSection",
  props: {},
  components: {},
  data: () => ({
    partners: [
      {
        logoSrc: "https://via.placeholder.com/65x65",
        link: "#"
      },
      {
        logoSrc: "https://via.placeholder.com/65x65",
        link: "#"
      },
      {
        logoSrc: "https://via.placeholder.com/65x65",
        link: "#"
      },
      {
        logoSrc: "https://via.placeholder.com/65x65",
        link: "#"
      },
      {
        logoSrc: "https://via.placeholder.com/65x65",
        link: "#"
      },
    ]
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.partners-section {
  border-bottom: 1px solid $blockstock-yellow;
  padding: 90px 0 160px;

  @media (max-width: $breakpoint-xs) {
    padding-bottom: 90px;
  }
}

.title {
  font-family: "HKNova-Bold", sans-serif;
  color: $blockstock-yellow;
  font-weight: 700;
  text-align: center;
  margin: 0 0 75px;
}

.partners {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-xs) {
    flex-wrap: wrap;
  }

  .logo {
    border-radius: 100%;
  }

  li {
    @media (max-width: $breakpoint-xs) {
      width: 50%;
      margin: 0 0 75px;

      &:nth-last-child(-n+2) {
        margin: 0;
      }
    }

    img {
      @media (max-width: $breakpoint-xs) {
        margin: 0 auto;
      }
    }
  }
}
</style>