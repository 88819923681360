<template>
  <div class="bso-and-ebso-section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 left-content-col">
          <div class="top-section">
            <h1 class="title headline-l">{{ $t("ebsoScreen.bsoAndEbso.leftCol.title") }}</h1>
            <span class="description" v-html="$t('ebsoScreen.bsoAndEbso.leftCol.description')" />
          </div>
          <div class="bottom-section">
            <span class="transfer-icon" />
            <span class="description">{{ $t("ebsoScreen.bsoAndEbso.leftCol.convert") }}</span>
          </div>
        </div>
        <div class="col-12 col-lg-6 right-content-col">
          <div class="gold-pool">
            <home-block-stock-diagram />
            <h1 class="title headline-l">{{ $t("ebsoScreen.bsoAndEbso.rightCol.title") }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeBlockStockDiagram from "@/components/modules/home/HomeBlockStockDiagram";

export default {
  name: "BsoAndEbsoSection",
  props: {},
  components: {
    HomeBlockStockDiagram
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.bso-and-ebso-section {
  padding: 195px 0 225px;
  border-bottom: 1px solid $ebso-section-border-color;

  @media (max-width: $breakpoint-sm) {
    padding: 70px 0;
  }

  @media (max-width: $breakpoint-md) {
    padding: 120px 0;
  }
}

.title {
  display: block;
  text-align: left;
  color: $blockstock-yellow;

  @media (max-width: $breakpoint-md) {
    margin: 0 0 30px;
  }
}

.description {
  font-size: 20px;
  line-height: 30px;
  display: block;

  @media (max-width: $breakpoint-sm) {
    font-size: 16px;
  }
}

.left-content-col {
  display: flex;
  flex-wrap: wrap;

  .bottom-section {
    padding: 40px 0 0;

    @media (max-width: $breakpoint-md) {
      margin: 120px 0 0;
      padding: 0 0 70px;
    }
  }

  .title {
    margin: 0 0 40px;
  }

  .transfer-icon {
    margin: 0 0 40px;
    display: inline-block;
    width: 34px;
    height: 20px;
    mask-image: url("/images/icons/bb-icon_convert.svg");
    mask-repeat: no-repeat;
    mask-size: contain;
    background: $blockstock-yellow;

    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }
}

.right-content-col {
  .gold-pool {
    padding-left: 100px;

    @media (max-width: $breakpoint-lg) {
      padding-left: 0;
    }

    .title {
      margin: 30px 0 0;
      font-size: 30px;
      text-align: center;

      // @media (max-width: $breakpoint-md) {
      //   margin: 0 0 70px;
      // }
    }
  }
}

#block-stock-diagram {
  margin: 0 auto;

  @media (max-width: $breakpoint-md) {
    width: 100% !important
  }

  @media (max-width: $breakpoint-xs) {
    width: calc(100% + 30px) !important;
    margin: 0 -15px;
  }

  &::v-deep {
    .title-row {
      @media (max-width: $breakpoint-md) {
        width: 100% !important
      }
    }

    .gold-backing-title {
      @media (max-width: $breakpoint-md) {
        display: none !important;
      }
    }
  }
}
</style>