<template>
  <div class="audit-and-steps-section">
    <div class="container">
      <h1 class="title headline-l">{{ $t("ebsoScreen.goldAudit.title") }}</h1>
      <div class="row audits">
        <template v-for="(audit, i) in audits">
          <div class="col-audit col-12 col-sm-3" :key="i">
            <div class="content-box">
              <img src="/images/icons/bb-icon-gold_file.svg" class="icon">
              <span class="label">{{ audit.date }}</span>
              <a :href="audit.assetUrl" class="download-btn">{{ $t("ebsoScreen.goldAudit.downloadButtonText") }}</a>
            </div>
          </div>
        </template>
      </div>
      <h1 class="title headline-l">{{ $t("ebsoScreen.howToGet.title") }}</h1>
      <div class="row steps">
        <template v-for="(step, i) in steps">
          <div class="col-step col-12 col-sm-4" :key="i">
            <div class="content-box">
              <div class="icon-wrapper">
                <img :src="step.iconSrc" class="icon">
              </div>
              <span class="label">{{ $t(step.label) }}</span>
              <span class="description">{{ $t(step.description) }}</span>
              <a
                v-if="i == 0"
                class="lbank-logo"
                target="_blank"
                href="https://www.lbank.info/invitevip/?icode=FIYR&lange=en-US#/web/register/email"
              >
                <img src="/images/logos/lbank-logo.svg">
              </a>
            </div>
          </div>
        </template>
      </div>
      <!-- <div class="partners-wrapper d-none d-md-block">
        <h1 class="title partners-title">{{ $t("ebsoScreen.partners.title") }}</h1>
        <div class="row partners">
          <template v-for="(partner, i) in partners">
            <div class="col-4" :key="i">
              <div class="partner">
                <a :href="partner.link">
                  <img :src="partner.logoSrc" class="logo">
                </a>
              </div>
            </div>
          </template>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "AuditAndStepsSection",
  props: {},
  components: {},
  data: () => ({
    audits: [
      {
        date: "2020.04.22",
        assetUrl: "https://data.blockben.com/AuAudit/Certification_BlockBen_2020.04.07.pdf"
      },
      {
        date: "2020.10.07",
        assetUrl: "https://data.blockben.com/AuAudit/Certification_BlockBen_2020.09.30.pdf"
      },
      {
        date: "2021.04.09",
        assetUrl: "https://data.blockben.com/AuAudit/Certification_BlockBen_2021.03.16.pdf"
      },
      {
        date: "2022.12.21",
        assetUrl: "https://data.blockben.com/AuAudit/Certification_BlockBen_2022.12.22.pdf"
      },
    ],
    steps: [
      {
        iconSrc: "/images/icons/bb-icon_gold-person-doc.svg",
        label: "ebsoScreen.howToGet.steps.first.label",
        description: "ebsoScreen.howToGet.steps.first.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_gold-transfer.svg",
        label: "ebsoScreen.howToGet.steps.second.label",
        description: "ebsoScreen.howToGet.steps.second.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_gold-cart.svg",
        label: "ebsoScreen.howToGet.steps.third.label",
        description: "ebsoScreen.howToGet.steps.third.description"
      },
    ],
    partners: [
      {
        logoSrc: "https://via.placeholder.com/65x65",
        link: "#"
      },
      {
        logoSrc: "https://via.placeholder.com/65x65",
        link: "#"
      },
      {
        logoSrc: "https://via.placeholder.com/65x65",
        link: "#"
      },
    ]
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.audit-and-steps-section {
  padding: 80px 0 85px;
  border-bottom: 1px solid $blockstock-yellow;
}

.title {
  color: $blockstock-yellow;
  text-align: center;
  margin: 0 0 90px;
}

.audits {
  margin: 0 0 130px;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-xs) {
    margin: 0 0 55px;
  }

  .col-audit {
    @media (max-width: $breakpoint-xs) {
      margin: 0 0 80px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.content-box {
  text-align: center;

  .icon {
    margin: 0 auto 20px;
    max-height: 40px;
  }

  .icon-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .label {
    display: block;
    font-family: "HKNova-Bold", sans-serif;
    color: $white;
    margin: 0 0 15px;
  }

  .download-btn {
    color: $white;
    font-family: "HKNova", sans-serif;
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.steps {
  margin: 0 0 100px;

  @media (max-width: $breakpoint-xs) {
    margin: 0;
  }

  .col-step {
    @media (max-width: $breakpoint-xs) {
      margin: 0 0 80px;

      &:last-child {
        margin: 0;
      }
    }

    .description {
      max-width: 200px;
      display: block;
      margin: 0 auto;
    }
  }

}

.partners-title {
  color: $white;
  font-family: "HKNova-Bold", sans-serif;
  margin: 0 0 70px;
  font-size: 24px;
  line-height: 30px;
}

.partner {
  display: flex;
  justify-content: center;
  
  a {
    width: auto;
    display: inline-block;
  }

  .logo {
    border-radius: 100%;
  }
}

.lbank-logo {
  margin: 10px 0 0;
  display: block;

  img {
    margin: 0 auto;
  }
} 
</style>